<template>
	<div class="card-list__cards">
		<div
			v-for="card in cards"
			:kay="card.ID"
			@click="$emit('handleChooseCard', card)"
			class="card-list__card"
		>
			<div class="card-list__card-info">
				<div class="card-list__card-icon">
					<img
						:src="card.PAY_SYSTEM?.UF_IMAGE"
						alt="Pay"
					>
				</div>
				{{ convertUfCardNumber(card.UF_CARD_NUMBER) }}
			</div>
			<CheckMarkIcon v-if="card.ID === currentCardId"/>
		</div>
	</div>
</template>
<script>
import CheckMarkIcon from "icons/24/check-mark.svg?inline";
import {convertUfCardNumber} from "@/helpers";
import {mapGetters} from "vuex";

export default {
	name: "CardList",
	props: {
		currentCardId: {
			type: String,
			default: ''
		}
	},
	components: {
		CheckMarkIcon
	},
	computed: {
		...mapGetters({
			cards: "personal/getCards"
		})
	},
	data()
	{
		return {
			savedCardId: ''
		}
	},
	methods: {
		convertUfCardNumber
	}
}
</script>
<style
	lang="scss"
>
.card-list__cards
{
	display: flex;
	flex-direction: column;
}

.card-list__card
{
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 56px;
	width: 100%;

	padding: 12px 16px;
	border-radius: 8px;

	font-family: $mainFontMediumNew;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: $textColor;

	transition: .2s;

	cursor: pointer;

	&:hover
	{
		background-color: $greyLightTextLight;
	}
}

.card-list__card-info
{
	display: flex;
	align-items: center;
	gap: 12px;
}

.card-list__card-icon
{
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	border-radius: 4px;
	width: 50px;
	height: 32px;
	background-color: $greyLightTextLight;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>