<template>
	<div class="payment-method__wrap">
		<div class="payment-method__header">
			<h2 class="payment-method__title">Способ оплаты</h2>
			<div
				class="payment-method-first-step__next-step"
				@click="$emit('handleChangeStep', 2)"
			>
				<p class="payment-method__subtitle">Выбрать карту по умолчанию</p>
				<ArrowIcon/>
			</div>
		</div>
		<div>
			<CardList
				@handleChooseCard="handleChooseCard"
				:currentCardId="currentCard.ID"
			/>
			<a
				href="/api/personal/saveCard"
				class="payment-method-first-step__new-card"
			>
				<div class="payment-method-first-step__new-card-icon">
					<PlusIcon/>
				</div>
				Добавить новую карту
			</a>
		</div>
	</div>
</template>
<script>
import {CardList} from '@/components/ui';
import PlusIcon from "icons/16/plus.svg?inline";
import ArrowIcon from "icons/10/arrow.svg?inline";
import {mapGetters, mapMutations} from "vuex";

export default {
	name: "FirstStep",
	components:
		{
			CardList,
			PlusIcon,
			ArrowIcon
		},
	computed:
		{
			...mapGetters({currentCard: "personal/getCurrentCard",})
		},
	methods:
		{
			...mapMutations({
				setCurrentCard: "personal/setCurrentCard",
			}),
			handleChooseCard(card)
			{
				this.setCurrentCard(card)
				this.$emit('closePopup')
			}
		}
}
</script>
<style
	lang="scss"
>
.payment-method-first-step__new-card
{
	display: flex;
	align-items: center;

	gap: 12px;
	height: 56px;
	width: 100%;

	padding: 12px 16px;
	border-radius: 8px;

	font-family: $mainFontMediumNew;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: $textColor;

	transition: .2s;

	cursor: pointer;

	&:hover
	{
		background-color: $greyLightTextLight;
	}
}

.payment-method-first-step__new-card-icon
{
	background-color: $greyDelimiter;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	border-radius: 4px;
	width: 50px;
	height: 32px;
}

.payment-method-first-step__next-step
{
	display: flex;
	align-items: center;
	gap: 4px;

	cursor: pointer;

	.payment-method__subtitle
	{
		color: $red;
	}
}
</style>